<script setup>
  import { provide } from 'vue'
  import useFreeQuotes from './compositions/free-quotes'
  const { freeQuote,
    isLoading,
    initFreeQuote,
    isLocalStorageAvailable,
    updateUUID,
    uuid,
    supportedFormats,
    translations
  }  =  useFreeQuotes()

  provide('uuid', {uuid, updateUUID})
  provide('isStorageEnabled', isLocalStorageAvailable())
  provide('supportedFormats', supportedFormats)
  provide('translations', translations)

  freeQuote.value.step = 'step1';
  initFreeQuote();

  function clickStep1to2() {
    freeQuote.value.step = 'step2'
  }
  function clickStep2to3() {
    freeQuote.value.step = 'step3'
  }
  function clickFinalOk() {
    freeQuote.value.step = 'step1';
    initFreeQuote();
  }

  import FinalizationStep from "@/components/FinalizationStep.vue";
  import AuthenticationStep from "@/components/AuthenticationStep.vue";
  import FilesCollectionStep from "@/components/FilesCollectionStep.vue";

</script>



<template>

  <div class="fq-section">

    <FilesCollectionStep v-if="freeQuote.step?.indexOf('step1')!==-1 || !freeQuote.step" v-model:isLoading="isLoading" :free-quote="freeQuote" @step1to2="clickStep1to2"/>

    <AuthenticationStep v-if="freeQuote.step && freeQuote.step.indexOf('step2')!==-1" v-model:isLoading="isLoading" :free-quote="freeQuote" @step2to3="clickStep2to3"/>

    <FinalizationStep v-if="freeQuote.step && freeQuote.step?.indexOf('step3')!==-1" v-model:isLoading="isLoading" :free-quote="freeQuote" @final-ok="clickFinalOk"/>

  </div>

</template>




<style>
  /*general*/
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;800&display=swap');
  .fq-section {
    font-family: "Open Sans", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color: #6F6F6F;
    line-height: 1.2;
  }
  .fq-container {
    box-sizing: border-box;
    background: #FBFBFB;
    border: 2px dashed #D9D9D9;
    border-radius: 20px;
    padding: 30px;
    min-height: 380px;
    max-width: 1100px;
    margin: 0 auto;
  }
  @media (max-width: 959px) {
    .fq-container {
      padding: 20px;
    }
  }
  canvas, img, svg, video {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
  }
  audio, canvas, iframe, img, svg, video {
    vertical-align: middle;
  }
  .fq-link-text a, a.fq-link-text {
    color: inherit;
  }
  .fq-top-tit {
    font-size: 20px;
    line-height: 1.2;
  }
  .fq-light {
    color: #fff;
  }
  .fq-background-green {
    background: #4caf50;
  }
  .fq-background-muted {
    background: #f8f8f8;
  }
  .fq-background-gray {
    background: #e3e3e3;
  }

  /*margin*/
  .fq-margin {margin-bottom: 20px;}
  *+.fq-margin {margin-top: 20px !important;}
  .fq-margin-medium {margin-bottom: 30px;}
  *+.fq-margin-medium {margin-top: 30px !important;}
  .fq-margin-large {margin-bottom: 40px;}
  *+.fq-margin-large {margin-top: 40px !important;}
  .fq-margin-top {margin-top: 20px !important;}
  .fq-margin-large-top {margin-top: 40px !important;}
  .fq-margin-large-bottom {margin-bottom: 40px !important;}
  .fq-margin-medium-top {margin-top: 30px !important;}
  .fq-margin-medium-bottom {margin-bottom: 30px !important;}
  .fq-margin-medium-right {margin-right: 30px !important;}
  .fq-margin-small-right {margin-right: 10px !important;}
  .fq-margin-small-top {margin-top: 10px !important;}
  .fq-margin-auto {margin-right: auto !important;margin-left: auto !important;}
  /* Phone landscape and bigger */
  @media (min-width: 640px) {
    .uk-margin-top\@s {
      margin-top: 20px !important;
    }
    .uk-margin-bottom\@s {
      margin-left: 20px !important;
    }
    .uk-margin-left\@s {
      margin-left: 20px !important;
    }
    .uk-margin-right\@s {
      margin-right: 20px !important;
    }
  }
  /* Tablet landscape and bigger */
  @media (min-width: 960px) {
    .uk-margin-top\@m {
      margin-top: 20px !important;
    }
    .uk-margin-bottom\@m {
      margin-left: 20px !important;
    }
    .uk-margin-left\@m {
      margin-left: 20px !important;
    }
    .uk-margin-right\@m {
      margin-right: 20px !important;
    }
  }
  /* Desktop and bigger */
  @media (min-width: 1200px) {
    .uk-margin-top\@l {
      margin-top: 20px !important;
    }
    .uk-margin-bottom\@l {
      margin-left: 20px !important;
    }
    .uk-margin-left\@l {
      margin-left: 20px !important;
    }
    .uk-margin-right\@l {
      margin-right: 20px !important;
    }
  }

  /*width*/
  [class*=fq-width] {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
  }
  .fq-width-1-1 {width: 100%;}
  .fq-width-1-2 {width: 50%;}
  .fq-width-small {max-width: 325px;}
  .fq-width-medium {max-width: 450px;}
  .fq-width-large {max-width: 650px;}
  .fq-width-auto {width: auto;}
  .fq-width-expand {
    flex: 1;
    min-width: 1px;
  }
  /* Phone landscape and bigger */
  @media (min-width: 640px) {
    .fq-width-1-1\@s {
      width: 100%;
    }
    .fq-width-1-2\@s {
      width: 50%;
    }
    .fq-width-auto\@s {
      width: auto;
    }
    .fq-width-expand\@s {
      flex: 1;
      min-width: 1px;
    }
  }
  /* Tablet landscape and bigger */
  @media (min-width: 960px) {
    .fq-width-1-1\@m {
      width: 100%;
    }
    .fq-width-1-2\@m {
      width: 50%;
    }
    .fq-width-auto\@m {
      width: auto;
    }
    .fq-width-expand\@m {
      flex: 1;
      min-width: 1px;
    }
  }
  /* Desktop and bigger */
  @media (min-width: 1200px) {
    .fq-width-1-1\@l {
      width: 100%;
    }
    .fq-width-1-2\@l {
      width: 50%;
    }
    .fq-width-auto\@l {
      width: auto;
    }
    .fq-width-expand\@l {
      flex: 1;
      min-width: 1px;
    }
  }

  /*grid*/
  .fq-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .fq-grid>* {
    margin: 0
  }
  .fq-grid>*>:last-child {
    margin-bottom: 0;
  }
  .fq-grid {
    margin-left: -20px;
  }
  .fq-grid>* {
    padding-left: 20px;
  }
  .fq-grid-collapse {
    margin-left: 0
  }
  .fq-grid-collapse>* {
    padding-left: 0
  }
  .fq-grid-small {
    margin-left: -15px;
  }
  .fq-grid-small>* {
    padding-left: 15px;
  }
  .fq-grid-xsmall {
    margin-left: -5px;
  }
  .fq-grid-xsmall>* {
    padding-left: 5px;
  }
  .fq-grid-divider>* {
    position: relative
  }
  .fq-grid-divider>:not(.fq-first-column)::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    border-left: 1px solid #e5e5e5;
  }
  .fq-grid-divider>*::before {
    left: 0;
  }


  /*flex*/
  .fq-flex {
    display: flex;
  }
  .fq-flex-middle {
    align-items: center;
  }
  .fq-flex-center {
    justify-content: center;
  }
  .fq-flex-inline {
    display: inline-flex;
  }
  .fq-flex-column {
    flex-direction: column;
  }
  .fq-text-center {
    text-align: center !important;
  }

  /*text*/
  .fq-text-uppercase {
    text-transform: uppercase !important;
  }
  .fq-text-bold {
    font-weight: 800;
  }
  .fq-text-small {
    font-size: 14px;
  }
  .fq-text-xsmall {
    font-size: 12px;
  }
  .fq-text-orange {
    color: #ffb231;
  }
  .fq-text-red {
    color: #ff3131;
  }
  .fq-text-primary {
    color: #16A1C0;
  }
  .fq-text-muted {
    color: #8F8F8F;
  }

  /*icon*/
  .fq-icon {
    margin: 0;
    border: none;
    border-radius: 0;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    padding: 0;
    background-color: transparent;
    display: inline-block;
    fill: currentcolor;
    line-height: 0;
  }
  .fq-icon-download {
    margin-right: 10px;
  }
  .fq-icon::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  .fq-icon>* {
    transform: translate(0, 0);
  }
  .fq-icon:not(.fq-preserve) [fill*='#']:not(.fq-preserve) {
    fill: currentcolor;
  }
  .fq-icon:not(.fq-preserve) [stroke*='#']:not(.fq-preserve) {
    stroke: currentcolor;
  }

  /*spinner*/
  .fq-spinner>* {
    animation: fq-spinner-rotate 1.4s linear infinite;
  }
  .fq-spinner {
    position: relative;
  }
  .fq-spinner:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: 2px solid #fff;
    border-radius: 500px;
  }
  @keyframes fq-spinner-rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(270deg);
    }
  }
  .fq-spinner>*>* {
    stroke-dasharray: 88px;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: fq-spinner-dash 1.4s ease-in-out infinite;
    stroke-width: 1;
    stroke-linecap: round;
  }
  @keyframes fq-spinner-dash {
    0% {
      stroke-dashoffset: 88px;
    }
    50% {
      stroke-dashoffset: 22px;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 88px;
      transform: rotate(450deg);
    }
  }

  /*button*/
  .fq-button {
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    cursor: pointer;
    text-transform: none;
    -webkit-appearance: none;
    border-radius: 500px;
    display: inline-block;
    box-sizing: border-box;
    padding: 15px 30px;
    vertical-align: middle;
    font-size: inherit;
    text-align: center;
    text-decoration: none;
    line-height: 1;
    transition: .2s ease-in-out;
    transition-property: color,background-color,background-position,background-size,border-color,box-shadow;
    font-weight: inherit;
    background-origin: border-box;
    background-size: 200%;
    background-position-x: 100%;
  }
  .fq-button-default {
    background-color: #fff;
    border: 2px solid #C5C5C5;
    color: #6F6F6F;
    padding: 10px 15px;
  }
  .fq-button-default:hover,
  .fq-button-default:focus {
    background-color: #C5C5C5;
    border: 2px solid #C5C5C5;
    color: #fff;
  }
  .fq-add-img-btn {
    background-color: #fff;
    padding: 10px;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fq-button-orange {
    background-color: #ffb231;
    border: 2px solid #ffb231;
    color: #fff;
    padding: 10px 15px;
  }
  .fq-button-orange:hover,
  .fq-button-orange:focus {
    background-color: #f5a112;
    border-color: #f5a112;
    color: #fff;
  }
  .fq-button-orange:disabled {
    cursor: not-allowed;
    color: #fff;
    border-color: #d2d2d2;
    background-color: #d2d2d2;
  }
  .fq-button-grey {
    background-color: #B7B7B7;
    border: 2px solid #B7B7B7;
    color: #fff;
    padding: 10px 15px;
  }
  .fq-button-grey:hover,
  .fq-button-grey:focus {
    background-color: #9a9a9a;
    border-color: #9a9a9a;
    color: #fff;
  }
  .fq-download-button {
    border-radius: 500px;
    font-size: 22px;
    padding: 30px 40px;
  }
  .fq-download-button:not(:disabled) {
    cursor: pointer
  }
  .fq-download-button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  .drop-area[data-active=true] .fq-download-button,
  .fq-download-button:hover {
    text-decoration: none;
    background-position-x: 0;
  }
  .fq-button-primary {
    background-color: #ffb231;
    color: #fff;
    border: 2px solid transparent;
    background-image: linear-gradient(51deg,#ffb231 50%,#ffae25 70%,#ff8a42 100%);
    box-shadow: -5px -5px 15px rgba(255,255,255,.9),5px 5px 15px rgba(163,39,0,.3);
  }
  .drop-area[data-active=true] .fq-button-primary,
  .fq-button-primary:active,
  .fq-button-primary:hover {
    background-color: #f5a112;
    color: #fff;
    border-color: transparent;
    background-image: linear-gradient(51deg,#ffb231 50%,#ffae25 70%,#ff8a42 100%);
    box-shadow: -2px -2px 5px rgba(255,255,255,.9),2px 2px 5px rgba(163,39,0,.3);
  }
  .fq-button-small {
    padding: 15px !important;
  }
  .fq-button-big {
    padding: 15px !important;
    font-size: 18px;
  }
  .fq-button-default:disabled {
    background-color: transparent;
    color: #999;
    border-color: #e5e5e5;
  }
  .fq-btns .fq-button {
    min-width: 190px;
  }
  @media (max-width: 639px) {
    .fq-btns .fq-button {
      min-width: 150px;
    }
    .fq-btns .fq-button:not(.fq-button-ok):not(.fq-edit-bottom-button):not(.fq-load-imgs-btn):not(.fq-button-next) {
      min-width: auto;
    }
    /*.fq-load-imgs-btns > div + div {
      margin-top: 10px;
    }*/
    .fq-download-button {
      font-size: 18px;
      padding: 25px 20px;
    }
  }
  .fq-button-circle-effect {
    position: relative;
    overflow: hidden !important;
    border: none !important;
  }
  .fq-button-circle-effect:after,
  .fq-button-circle-effect:before {
    content: "";
    position: absolute;
    top: calc(50% - 37px);
    left: calc(50% - 37px);
    background: hsla(0, 0%, 100%, .4);
    border-radius: 100px;
    opacity: 0;
    width: 74px;
    height: 74px;
    z-index: 100
  }
  .fq-button-circle-effect:after {
    content: "";
    position: absolute;
    top: calc(50% - 37px);
    left: calc(50% - 37px);
    background: hsla(0, 0%, 100%, .4);
    border-radius: 100px;
    opacity: 0;
    width: 74px;
    height: 74px;
    z-index: 10;
    -webkit-animation: button-circle-effect 3s linear 1s infinite;
    animation: button-circle-effect 3s linear 1s infinite;
  }
  @-webkit-keyframes button-circle-effect {
    0% {
      -webkit-transform: scale(.2);
      transform: scale(.2);
      opacity: 0;
    }
    20% {
      -webkit-transform: scale(2);
      transform: scale(2);
      opacity: 1;
    }
    50% {
      -webkit-transform: scale(4);
      transform: scale(4);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(4);
      transform: scale(4);
      opacity: 0;
    }
  }
  @keyframes button-circle-effect {
    0% {
      -webkit-transform: scale(.2);
      transform: scale(.2);
      opacity: 0;
    }
    20% {
      -webkit-transform: scale(2);
      transform: scale(2);
      opacity: 1;
    }
    50% {
      -webkit-transform: scale(4);
      transform: scale(4);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(4);
      transform: scale(4);
      opacity: 0;
    }
  }

  /*position*/
  [class*=fq-position-center] {
    position: absolute !important;
    max-width:100%;
    box-sizing: border-box;
  }
  .fq-position-cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .fq-position-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
  }
  .fq-position-relative {
    position: relative !important;
  }

  /*overlay*/
  .fq-overlay-default {
    background: rgba(150, 150, 150, 0.5);
  }
  .fq-overlay {
    padding: 30px 30px;
  }

  /*form*/
  .fq-input, .fq-textarea {
    -webkit-appearance: none;
  }
  input[type=file]:not(:focus-visible) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .fq-checkbox, .fq-input, .fq-textarea, .fq-select {
    box-sizing: border-box;
    margin: 0;
    border-radius: 0;
    font: inherit;
  }
  .fq-input, .fq-textarea, .fq-select {
    max-width: 100%;
    width: 100%;
    padding: 10px;
    background: #fff;
    color: #666;
    border: 2px solid #d0d0d0;
    transition: .2s ease-in-out;
    transition-property: color, background-color, border;
  }
  .fq-textarea {
    padding-top: 6px;
    padding-bottom: 6px;
    vertical-align: top;
    overflow: auto;
    min-height: 90px;
    resize: vertical;
  }
  .fq-checkbox, .fq-radio {
    cursor: pointer;
  }
  .fq-checkbox, .fq-radio {
    display: inline-block;
    height: 16px;
    width: 16px;
    overflow: hidden;
    margin-top: -4px;
    border-radius: 4px;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 2px solid #16A1C0;
    transition: .2s ease-in-out;
    transition-property: background-color,border;
  }
  .fq-radio {
    border-radius: 50%;
  }
  .fq-checkbox:focus, .fq-radio:focus {
    background-color: rgba(0,0,0,0);
    outline: 0;
    border-color: #16A1C0;
  }
  .fq-checkbox:checked, .fq-radio:checked {
    background-color: #16A1C0;
    border-color: transparent;
  }
  .fq-checkbox:checked:focus, .fq-radio:checked:focus {
    background-color: #11859f;
  }
  .fq-checkbox:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  }
  .fq-radio:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
  }
  .fq-radio:disabled, .fq-checkbox:disabled {
    background-color: #f8f8f8;
    border-color: #e5e5e5;
    cursor: not-allowed;
  }
  .fq-radio:disabled:checked, .fq-checkbox:disabled:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  }
  .fq-input:focus, .fq-textarea:focus, .fq-select:focus {
    outline: 0;
    background-color: #fff;
    color: #666;
    border-color: #ffb231;
  }
  .fq-input, .fq-select:not([multiple]):not([size]) {
    height: 44px;
    vertical-align: middle;
    display: inline-block;
  }
  .fq-input:not(input), .fq-select:not(select) {
    line-height: 42px;
  }
  .fq-select[multiple], .fq-select[size], .fq-textarea {
    padding-top: 6px;
    padding-bottom: 6px;
    vertical-align: top;
  }
  .fq-select[multiple], .fq-select[size] {
    resize: vertical;
  }
  .fq-input:disabled, .fq-select:disabled, .fq-textarea:disabled {
    background-color: #f8f8f8;
    color: #999;
    border-color: #e5e5e5;
    cursor: not-allowed;
  }
  .fq-select {
    text-transform: none;
  }
  .fq-select optgroup {
    font: inherit;
    font-weight: 700;
  }
  .fq-select:not([multiple]):not([size]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 20px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: 100% 50%;
  }
  .fq-select:not([multiple]):not([size]) option {
    color: #666;
  }
  .fq-select:not([multiple]):not([size]):disabled {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  }

  ::-webkit-input-placeholder {color: #dadada}
  ::-moz-placeholder {color: #dadada}
  :-moz-placeholder {color: #dadada}
  :-ms-input-placeholder {color: #dadada}
  :focus::-webkit-input-placeholder {color: transparent}
  :focus::-moz-placeholder {color: transparent}
  :focus:-moz-placeholder {color: transparent}
  :focus:-ms-input-placeholder {color: transparent}
  .fq-google-btn-outline {
    box-sizing: border-box;
    border-radius: 50px;
    height: 44px;
    border: 2px solid transparent;
  }

  /*validation*/
  .fq-validation.fq-google-btn-outline,
  .fq-validation.fq-checkbox,
  .fq-validation.fq-radio,
  .fq-validation.fq-select,
  .fq-validation.fq-input,
  .fq-validation.fq-textarea {
    border-color: #ff6e6e;
  }

  /*image box*/
  .fq-img-box {
    width: 90px;
    height: 90px;
    overflow: hidden;
  }
  .fq-img-box img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    display: block;
    border: 1px solid transparent;
    box-sizing: border-box;
  }
  .fq-img-edit-btn,
  .fq-img-del-btn {
    color: #C5C5C5;
    position: absolute !important;
    box-sizing: border-box;
    border-radius: 50px;
    cursor: pointer;
    height: 24px;
    width: 24px;
    background-color: #fff;
    transition: .1s ease-in-out;
    transition-property: color, background-color, background-position, background-size, border-color, box-shadow;
  }
  .fq-img-del-btn {
    top: -12px;
    right: -12px;
    border: 2px solid #C5C5C5;
    padding: 0;
  }
  .fq-img-edit-btn {
    bottom: 2px;
    right: 2px;
    padding: 3px;
  }
  .fq-img-edit-btn:hover,
  .fq-img-del-btn:hover {
    background-color: #ffb231;
    color: #fff;
    border-color: #ffb231;
  }

  /*alert*/
  .fq-alert {
    position: relative;
    padding: 15px 29px 15px 15px;
    background: #f8f8f8;
    color: #666;
    width: 100%;
    max-width: 500px;
    margin: 20px 0;
  }
  .fq-alert-warning {
    background: #fff6ee;
    color: #faa05a;
  }
  .fq-alert-error {
    background: #ffeeee;
    color: #fa5a5a;
  }
  .fq-alert-info {
    background: #eefaff;
    color: #5ae7fa;
  }
  .fq-alert-success {
    background: #f2ffee;
    color: #5afa7a;
  }
  .fq-alert-close {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 15px;
    color: inherit;
    opacity: .4;
    transition: .1s ease-in-out;
    transition-property: color, opacity;
  }
  .fq-alert-close:first-child+* {
    margin-top: 0
  }
  .fq-alert-close:hover {
    color: inherit;
    opacity: .8;
  }

  /*utility*/
  .fq-overflow-auto {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .fq-border-rounded {
    border-radius: 10px !important;
  }
  .fq-box-shadow-small {box-shadow: 0 2px 8px rgba(0, 0, 0, .08);}
  .fq-box-shadow-medium {box-shadow: 0 5px 15px rgba(0, 0, 0, .08);}
  .fq-box-shadow-large {box-shadow: 0 14px 25px rgba(0, 0, 0, .16);}
  .fq-box-shadow-hover-large:hover {box-shadow: 0 14px 25px rgba(0, 0, 0, .16);}

  /*animation*/
  [class*=fq-animation-] {animation: .5s ease-out both;}
  .fq-animation-slide-bottom-small {
    animation-name: fq-fade, fq-slide-bottom-small;
  }
  .fq-animation-fade {animation: fq-fade .4s linear;}
  @keyframes fq-fade {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  @keyframes fq-slide-bottom-small {
    0% {
      transform: translateY(50px);
    }
  }

  /*drop area*/
  .drop-area {
    transition: .2s ease-in-out;
    transition-property: color,background-color,background-position,background-size,border-color,box-shadow;
  }
  .drop-area[data-active=true] {
    background: #FFFFFF;
    border-color: #D0D0D0;
  }
  .drop-area[data-active=true].fq-box-shadow-medium {
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12);
  }

  /*img edit modal*/

  /*progress (not in use for now)*/
  .fq-progress {
    vertical-align: baseline;
    display: block;
    width: 100%;
    border: 0;
    background-color: #f8f8f8;
    height: 10px;
    border-radius: 500px;
    overflow: hidden;
  }
  .fq-progress::-webkit-progress-bar {
    background-color: transparent
  }
  .fq-progress::-webkit-progress-value {
    background-color: #ffb231;
    transition: width .6s ease;
  }
  .fq-progress::-moz-progress-bar {
    background-color: #ffb231;
    transition: width .6s ease;
  }
</style>