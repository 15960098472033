<script setup>
import {computed, defineModel, inject, ref} from "vue";

const props = defineProps(['freeQuote'])
const emit = defineEmits(['final-ok'])
const isLoading = defineModel('isLoading')
const apiUrl = inject('apiURL')
const step = ref(props.freeQuote.step);
const isStorageEnabled = inject('isStorageEnabled');
const { uuid, updateUUID } = inject('uuid');

const form = ref({
  use_service_for: '',
  company_name:'',
  company_website:'',
  company_images_per_year:'',
});
const errors = ref({});

const validateField = (field) => {
  errors.value[field] = '';
  console.log("Validate " + field +' '+ form.value[field]);
  if (field === 'use_service_for' && !form.value[field]) {
    errors.value[field] = 'Please select what kind your bussiness is?';
  }
  if (form.value['use_service_for'] == 'corporate') {
    if (field === 'company_name' && !form.value[field]) {
      errors.value[field] = 'Please enter your Company Name';
    }
    if (field === 'company_images_per_year' && !form.value[field]) {
      errors.value[field] = 'Please select How many images do you edit per year.';
    }
  }

};
const isFormValid = computed(() => Object.keys(errors.value).every( (error) => !(errors.value[error]) ));
const isFinishButtonEnabled = computed(() => {
  // return form.value.use_service_for =='personal' || form.value.use_service_for =='corporate'
  return true;
});

function clickFinish(){
  ['use_service_for', 'company_name', 'company_images_per_year'].forEach((field)=>validateField(field))
  if (isFormValid.value) {

    isLoading.value = true
    let formData = new FormData()
    Object.entries({
      action: 'updateQuiz',
      uuid: uuid.value,
      step: "step3-finish",
      ...form.value
    }).forEach(([key, value]) => formData.append(key, value))

    fetch(apiUrl, {
      method: 'POST',
      body: formData,
    }).then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            console.log("Credentials data has been sent Successfully")
          } else {
            console.log("Error Saving Credentials: " + data.error)
          }
        })
        .catch((error)=>console.log(error))
        .finally(()=>{
          isLoading.value = false
          step.value = 'step3-finish'
        })
  }

}
function clickOk(){
  updateUUID('')
  if ( isStorageEnabled ) {
    localStorage.removeItem('uuid')
  }
  emit('final-ok');
}

import LoadingScreen from "@/components/partials/LoadingScreen.vue";

</script>


<template>

  <!-- ******* -->
  <!-- step 3-0. Congratulations -->
  <!-- ******* -->

  <div v-if="step === 'step3'" class="fq-container fq-box-shadow-medium fq-flex fq-flex-center fq-flex-middle fq-flex-column fq-overflow-auto fq-position-relative fq-animation-fade fq-box-shadow-hover-large">
    <div>
      <!--mess-->
      <div class="fq-text-center fq-margin-large-bottom">
        <!--Success-->
        <div class="fq-grid fq-grid-collapse fq-flex-center fq-flex-column">
          <div class="fq-text-primary">
            <div class="fq-icon-send fq-icon">
              <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="60" height="60">
                <path d="M11.7,2c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0v0c-0.2,0-0.3,0-0.5,0l0.2,2c0.4,0,0.9,0,1.3,0c4,0.3,7.3,3.5,7.5,7.6
                c0.2,4.4-3.2,8.2-7.6,8.4c0,0-0.1,0-0.2,0c-0.3,0-0.7,0-1,0L11,22c0.4,0,0.8,0,1.3,0c0.1,0,0.3,0,0.4,0v0c5.4-0.4,9.5-5,9.3-10.4
                c-0.2-5.1-4.3-9.1-9.3-9.5v0c0,0,0,0,0,0c-0.2,0-0.3,0-0.5,0C12,2,11.9,2,11.7,2z M8.2,2.7C7.7,3,7.2,3.2,6.7,3.5l1.1,1.7
                C8.1,5,8.5,4.8,8.9,4.6L8.2,2.7z M4.5,5.4c-0.4,0.4-0.7,0.9-1,1.3l1.7,1C5.4,7.4,5.7,7.1,6,6.7L4.5,5.4z M15.4,8.4l-4.6,5.2
                l-2.7-2.1L7,13.2l4.2,3.2l5.8-6.6L15.4,8.4z M2.4,9c-0.2,0.5-0.3,1.1-0.3,1.6l2,0.3c0.1-0.4,0.1-0.9,0.3-1.3L2.4,9z M4.1,13l-2,0.2
                c0,0.1,0,0.2,0,0.3c0.1,0.4,0.2,0.9,0.3,1.3l1.9-0.6c-0.1-0.3-0.2-0.7-0.2-1.1L4.1,13z M5.2,16.2l-1.7,1.1c0.3,0.5,0.6,0.9,1,1.3
                L6,17.3C5.7,16.9,5.4,16.6,5.2,16.2z M7.8,18.8l-1.1,1.7c0.5,0.3,1,0.5,1.5,0.8l0.8-1.8C8.5,19.2,8.1,19,7.8,18.8z"/>
                <rect style="fill:none" width="24" height="24"/>
              </svg>
            </div>
          </div>
          <div class="fq-margin-medium-top" style="line-height: 1.6">
            Thank you, your order submitted successfully!
          </div>

          <div class="fq-grid fq-margin fq-flex-center">
            <div class="fq-width-auto">
              <div class="fq-grid fq-grid-xsmall">
                <div class="fq-width-auto">
                  <input
                      :class="(errors.use_service_for?'fq-validation ':'') + 'fq-radio'"
                      v-model="form.use_service_for"
                      @change="validateField('use_service_for'); errors={}"
                      type="radio" value="personal" name="use_service_for" id="use_service_for_personal" >
                </div>
                <div class="fq-width-auto">
                  <label for="use_service_for_personal">
                    For personal use
                  </label>
                </div>
              </div>
            </div>
            <div class="fq-width-auto">
              <div class="fq-grid fq-grid-xsmall">
                <div class="fq-width-auto">
                  <input
                      :class="(errors.use_service_for?'fq-validation ':'') + 'fq-radio'"
                      v-model="form.use_service_for"
                      @change="validateField('use_service_for'); errors={}"
                      type="radio" value="corporate" name="use_service_for" id="use_service_for_corporate" >
                </div>
                <div class="fq-width-auto">
                  <label for="use_service_for_corporate">
                    Corporate
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-show="form.use_service_for === 'corporate'" class="fq-grid fq-animation-fade fq-user-service-form" data-use-service-for="corporate">
            <div class="fq-width-1-2@s fq-margin-top">
              <input
                  :class="(errors.company_name?'fq-validation ':'') + 'fq-input fq-border-rounded'"
                  v-model="form.company_name"
                  @change="validateField('company_name')"
                  type="text" maxlength="255" id="company_name" name="company_name" value="" placeholder="Company name*">
            </div>
            <div class="fq-width-1-2@s fq-margin-top">
              <input
                  :class="(errors.company_website?'fq-validation ':'') + 'fq-input fq-border-rounded'"
                  v-model="form.company_website"
                  @change="validateField('company_website')"
                  type="text" maxlength="255" id="company_website" name="company_website" value="" placeholder="Company website">
            </div>
            <div class="fq-width-1-1 fq-margin-top">
              <select
                  :class="(errors.company_images_per_year?'fq-validation ':'') + 'fq-select fq-border-rounded'"
                  v-model="form.company_images_per_year"
                  @change="validateField('company_images_per_year')"
                  name="company_images_per_year" id="company_images_per_year">
                <option value="" disabled="" selected="">How many images do you edit per year?*</option>
                <option value="1">Less than 1,000</option>
                <option value="2">1,000-2,500</option>
                <option value="3">10,000-50,000</option>
                <option value="4">50,000-250,000</option>
                <option value="5">More than 250,000</option>
              </select>
            </div>
          </div>

        </div>
      </div>
      <!--btn Finish-->
      <div class="fq-text-center fq-animation-fade fq-btns">
        <button id="step4-ok" type="button" class="fq-button fq-button-orange fq-button-big fq-button-circle-effect" @click.prevent="clickFinish" :disabled="!isFinishButtonEnabled">
          Finish
        </button>
      </div>
    </div>
    <LoadingScreen v-if="isLoading"/>
  </div>

  <!-- ******* -->
  <!-- step 3-1. Congratulations -->
  <!-- ******* -->

  <div v-if="step === 'step3-finish'" class="fq-container fq-box-shadow-medium fq-flex fq-flex-center fq-flex-middle fq-flex-column fq-overflow-auto fq-position-relative fq-animation-fade fq-box-shadow-hover-large">
    <div>
      <!--mess-->
      <div class="fq-text-center fq-margin-large-bottom">
        <!--Success-->
        <div class="fq-grid fq-grid-collapse fq-flex-center fq-flex-column">
          <div class="fq-text-primary">
            <div class="fq-icon-send fq-icon">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_708_65)">
                  <path d="M17.7 24.3L13.5 28.5L27 42L57 12L52.8 7.8L27 33.6L17.7 24.3ZM54 30C54 43.2 43.2 54 30 54C16.8 54 6 43.2 6 30C6 16.8 16.8 6 30 6C32.4 6 34.5 6.3 36.6 6.9L41.4001 2.1C37.8 0.9 33.9 0 30 0C13.5 0 0 13.5 0 30C0 46.5 13.5 60 30 60C46.5 60 60 46.5 60 30H54Z" fill="#16A1C0"/>
                </g>
                <defs>
                  <clipPath id="clip0_708_65">
                    <rect width="60" height="60" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="fq-margin-medium-top">Your quote was sent successfully</div>
          <div class="fq-margin-medium-top" style="line-height:1.6;"> We answer as quickly as we can, but feel free to reach out to us at <a class="fq-link-text" href="mailto:fixthephotocom@gmail.com">fixthephotocom@gmail.com.</a></div>
        </div>
        <!-- Error  -->
        <!-- todo: Перенести этот блок в отдельный компонент ErrorScreen по аналогии LoadingScreen и юзать его на всех шагах для обработки ошибок -->
        <div v-if="false" class="fq-grid fq-grid-collapse fq-flex-center fq-flex-column">
          <div class="fq-text-red">
            <div class="fq-icon-error fq-icon">
              <svg width="50" height="50" viewBox="0 0 20 20">
                <circle cx="10" cy="14" r="1"></circle>
                <circle fill="none" stroke="#000" stroke-width="1.1" cx="10" cy="10" r="9"></circle>
                <path d="M10.97,7.72 C10.85,9.54 10.56,11.29 10.56,11.29 C10.51,11.87 10.27,12 9.99,12 C9.69,12 9.49,11.87 9.43,11.29 C9.43,11.29 9.16,9.54 9.03,7.72 C8.96,6.54 9.03,6 9.03,6 C9.03,5.45 9.46,5.02 9.99,5 C10.53,5.01 10.97,5.44 10.97,6 C10.97,6 11.04,6.54 10.97,7.72 L10.97,7.72 Z"></path>
              </svg>
            </div>
          </div>
          <div class="fq-margin-medium-top" style="line-height: 1.6">
            Error: Something went wrong! Your order was not sent. <br>Please contact our support team at <a class="fq-link-text" href="mailto:fixthephotocom@gmail.com">fixthephotocom@gmail.com</a> for assistance.
          </div>
        </div>
      </div>
      <!--btn OK-->
      <div class="fq-text-center fq-animation-fade fq-btns">
        <button id="step4-ok" type="button" class="fq-button fq-download-button fq-button-primary fq-button-small fq-button-ok" @click.prevent="clickOk">
          Ok
        </button>
      </div>
    </div>
    <LoadingScreen v-if="isLoading"/>
  </div>

</template>