<script setup>
import {computed, ref, defineModel, inject} from "vue";
import { decodeCredential } from 'vue3-google-login'

import LoadingScreen from "@/components/partials/LoadingScreen.vue";

defineProps(['freeQuote'])
const emit = defineEmits(['step2to3'])
const isLoading = defineModel('isLoading')
const isLoggedIn = ref(false)
const apiUrl = inject('apiURL')
const { uuid } = inject('uuid');

const form = ref({
  privacy: true,
  email:'',
  name:'',
  given_name:'',
});
const errors = ref({});

const gLoginCallback = (response) => {
  const userData = decodeCredential(response.credential)
  isLoggedIn.value = true;
  form.value.email = userData.email;
  form.value.name = userData.name;
  form.value.given_name = userData.given_name;
}

const validateField = (field) => {
  errors.value[field] = '';
  if (field === 'privacy' && !form.value[field]) {
    errors.value[field] = 'Please agree our Privacy Policy rules';
  }
  if (field === 'gLogin' && !isLoggedIn.value) {
    errors.value[field] = 'Please click Google button';
  }
};
const isFormValid = computed(() => Object.keys(errors.value).every( (error) => !(errors.value[error]) ));

function clickSendRequest() {
  ['privacy', 'gLogin'].forEach((field)=>validateField(field))

  // clear google button red outline that means not logged-in user
  // if (!isLoggedIn.value) {
  //   setTimeout(()=>{
  //     errors.value['gLogin'] = ''
  //   }, 3000)
  // }

  if (isFormValid.value) {
    isLoading.value = true
    let formData = new FormData()
    Object.entries({
      action: 'updateCredentials',
      uuid: uuid.value,
      step: "step3",
      ...form.value
    }).forEach(([key, value]) => formData.append(key, value))

    fetch(apiUrl, {
      method: 'POST',
      body: formData,
    }).then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          console.log("Credentials data has been sent Successfully")
        } else {
          console.log("Error Saving Credentials: " + data.error)
        }
      })
      .catch((error)=>console.log(error))
      .finally(()=>{
        isLoading.value = false
        emit('step2to3')
      })
  }
}


</script>


<template>

  <!-- ******* -->
  <!-- step 2 - Google auth  -->
  <!-- ******* -->

  <div class="fq-container fq-box-shadow-medium fq-flex fq-flex-center fq-flex-middle fq-flex-column fq-overflow-auto fq-position-relative fq-animation-fade fq-box-shadow-hover-large">
    <div v-if="!isLoggedIn" class="fq-top-tit fq-margin-large-bottom">
      Continue with Google *
    </div>
    <div class="fq-margin-large-bottom" style="max-width: 400px;">
      <div v-if="!isLoggedIn" :class="'fq-google-btn-outline ' + (errors.gLogin?'fq-validation ':'') + ' '">
        <GoogleLogin :callback="gLoginCallback" :buttonConfig="{theme:'outline', size:'large', text:'continue_with', shape:'pill'}"/>
      </div>
      <div v-if="isLoggedIn" class="fq-google-user fq-box-shadow-small">
        <div class="fq-grid fq-flex-middle fq-grid-small">
          <div>
            <div class="fq-google-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </div>
          </div>
          <div>
            <div class="fq-google-user-name">{{form.name}}</div>
            <div class="fq-google-user-email">{{form.email}}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoggedIn" class="fq-grid fq-grid-small fq-text-xsmall fq-text-muted fq-width-large fq-margin-auto fq-margin-large-bottom">
      <div class="fq-width-auto">
        <input id="checkbox_privacy" v-model="form.privacy" :class="(errors.privacy?'fq-validation ':'') + 'fq-checkbox'" type="checkbox" @change="validateField('privacy')" style="margin-top: 3px;">
      </div>
      <div class="fq-width-expand">
        <label for="checkbox_privacy" style="display: block">
          By logging in with your Google account, you consent to share your basic information (e.g., name, email, profile picture) with us to manage your account in line with our <a class="fq-link-text" href="https://fixthephoto.com/privacy-policy" target="_blank">Privacy Policy</a>. Google may also process your data per their <a class="fq-link-text" href="https://fixthephoto.com/privacy-policy" target="_blank">Privacy Policy</a>.
        </label>
      </div>
    </div>
    
    <!--btns (Proceed) -->
    <div class="fq-text-center fq-animation-fade fq-btns">
      <button type="submit" id="submitBtn" class="fq-button fq-button-orange fq-button-big fq-button-circle-effect" @click.prevent="clickSendRequest">
        Proceed &rarr;
      </button>
    </div>
    <LoadingScreen v-if="isLoading"/>
  </div>
</template>



<style>
  .fq-google-user {
    border-radius: 50px;
    max-width: 400px;
    min-width: 200px;
    -webkit-user-select: none;
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dadce0;
    color: #3c4043;
    font-size: 12px;
    letter-spacing: 0.25px;
    padding: 10px 20px;
    text-align: left;
  }
  .fq-google-user-name, .fq-google-user-email {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.4;
  }
  .fq-google-user-name {
    font-weight: bold;
  }
  .fq-google-user-email {
    color: #5f6368;
    font-size: 11px;
  }
  .fq-google-icon {
    height: 22px;
    min-width: 22px;
    width: 22px;
    display: block;
  }

  .fq-google-btn-name {
    font-size: 16px;
    line-height: 1;
  }
  .fq-google-user-btn:hover,
  .fq-google-user-btn:focus {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background-color: #f8f8f8;
  }
</style>